import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm"
import { SocialGrid, Map } from "../components/common"

const ContactPage = () => (
  <Layout>
    <div
      css={theme => css`
        margin: ${theme.constants.pageMargin};

        @media (max-width: 1200px) {
          margin: ${theme.constants.mediumpageMargin};
        }

        @media (max-width: 1000px) {
          margin: ${theme.constants.smallpageMargin};
        }

        @media (max-width: 880px) {
          margin: 0;
        }
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          css={css`
            width: 42%;

            @media (max-width: 880px) {
              width: 100%;
              padding: 0 20px;
            }
          `}
        >
          <h1
            css={theme => css`
              font-size: ${theme.constants.largeFontSize}px;
              font-weight: ${theme.constants.demi};
              margin-bottom: 50px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.mediumFontSize}px;
              }
            `}
          >
            Contact us
          </h1>
          <p
            css={theme => css`
              font-size: ${theme.constants.mediumFontSize}px;
              font-weight: ${theme.constants.extraLight};
              margin-bottom: 50px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.smallFontSize}px;
                margin-bottom: 50px;
              }
            `}
          >
            We are here to help you. Drop us a few lines, we will get back to
            you within a business day.
          </p>
          <p
            css={theme => css`
              font-size: ${theme.constants.smallFontSize}px;
              font-weight: ${theme.constants.extraLight};
              line-height: 1.5;

              margin-bottom: 85px;

              @media (max-width: 880px) {
                font-size: ${theme.constants.infoDescriptionTextSize}px;
                margin-bottom: 30px;
              }
            `}
          >
            Get in touch with us. We are always seeking new challenges and would
            love to get involved with your next exciting digital project. We
            want to really get to know your company - so we can lead you down on
            the path of the best digital solution.
          </p>
        </div>
        <div
          css={css`
            position: absolute;
            width: 50%;
            right: 0;
            top: 0;
            z-index: 100;

            @media (max-width: 880px) {
              position: initial;
              width: 100%;
            }
          `}
        >
          <ContactForm />
        </div>
      </div>
    </div>
    <Map />
    <SocialGrid />
  </Layout>
)

export default ContactPage
