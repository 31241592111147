import React, { useState } from "react"
import { css } from "@emotion/core"
import axios from "axios"
import { useFormInput } from "../hooks/useFormInput"
import { useWindowWidth } from "../hooks/useWindowWidth"

import {
  Input,
  GradientButton,
  Dropdown,
  Spinner,
  GradientCircleImage,
} from "./common"

import TickImage from "../images/common/tick.svg"

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const name = useFormInput("")
  const email = useFormInput("")
  const budget = useFormInput("")
  const topic = useFormInput("")
  const idea = useFormInput("")

  const width = useWindowWidth()

  const clearState = () => {
    name.onChange({ target: { value: "" } })
    email.onChange({ target: { value: "" } })
    budget.onChange({ target: { value: "" } })
    topic.onChange({ target: { value: "" } })
    idea.onChange({ target: { value: "" } })
  }

  const submitData = async () => {
    setSubmitting(true)
    try {
      const response = await axios.post(
        `${process.env.GATSBY_BACKEND_URL}/contact`,
        {
          name: name.value,
          email: email.value,
          budget: budget.value,
          topic: topic.value,
          idea: idea.value,
        }
      )
      if (response.data.response === "ok") {
        clearState()
        setSubmitting(false)
        setSubmitted(true)
      } else {
        throw new Error("Couldn't send")
      }
    } catch (err) {
      setSubmitting(false)
    }
  }

  if (submitted) {
    return (
      <div
        css={theme => css`
          background: ${theme.colors.veryDarkgray};
          color: ${theme.colors.white};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          padding: 60px;
          height: 690px;

          @media (max-width: 880px) {
            padding: 20px;
          }
        `}
      >
        <GradientCircleImage
          fillColor="rgb(35, 48, 65)"
          border={2}
          size={120}
          src={TickImage}
        />
        <div
          css={theme => css`
            font-size: ${theme.constants.mediumFontSize}px;
            font-weight: ${theme.constants.demi};

            margin-top: 60px;

            @media (max-width: 600px) {
              font-size: ${theme.constants.smallFontSize}px;
            }
          `}
        >
          Thank you!
        </div>
        <div
          css={theme => css`
            font-size: ${theme.constants.contactBoxDescriptionSize}px;
            color: ${theme.colors.darkGray};

            margin-top: 30px;
            margin-bottom: 60px;

            @media (max-width: 600px) {
              font-size: ${theme.constants.extraSmallFontSize}px;
            }
          `}
        >
          Your message has been sent. successfully. We will reply in 5 to 10
          business days.
        </div>
        <button
          css={theme => css`
            color: ${theme.colors.blue};
            font-size: ${theme.constants.extraSmallFontSize}px;
            font-weight: ${theme.constants.medium};
            background: transparent;
            cursor: pointer;
          `}
          onClick={() => setSubmitted(false)}
        >
          Send new message
        </button>
      </div>
    )
  }

  if (submitting) {
    return (
      <div
        css={theme => css`
          background: ${theme.colors.veryDarkgray};
          color: ${theme.colors.white};

          padding: 60px;
          height: 690px;

          @media (max-width: 880px) {
            padding: 20px;
          }
        `}
      >
        <Spinner fillColor="rgba(36, 37, 45, 1)" size={150} />
      </div>
    )
  }

  return (
    <div
      css={theme => css`
        background: ${theme.colors.veryDarkgray};
        color: ${theme.colors.white};
        padding: 60px;
        height: 690px;

        @media (max-width: 880px) {
          padding: 20px;
        }
      `}
    >
      <div
        css={theme => css`
          font-size: ${theme.constants.mediumFontSize}px;
          font-weight: ${theme.constants.demi};
          margin-bottom: 60px;

          @media (max-width: 880px) {
            margin-top: 30px;
          }

          @media (max-width: 600px) {
            font-size: ${theme.constants.smallFontSize}px;
          }
        `}
      >
        Don&apos;t be shy - say hi!
      </div>
      <form onSubmit={e => e.preventDefault()}>
        <Input name="name" {...name} placeholder="Full name" />
        <Input
          name="email"
          type="email"
          {...email}
          placeholder="Your mail address"
        />
        <Dropdown
          name="budget"
          {...budget}
          placeholder="Select your budget"
          options={[
            "1.000 - 5.000 €",
            "5.000 - 10.000 €",
            "10.000 - 20.000 €",
            "20.000 - 50.000 €",
            "50.000 € +",
          ]}
        />
        <Input name="topic" {...topic} placeholder="Topic" />
        <Input name="idea" {...idea} placeholder="Type your idea..." />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            width: 100%;
          `}
        >
          <GradientButton
            disabled={
              !name.value ||
              !email.value ||
              !budget.value ||
              !topic.value ||
              !idea.value
            }
            onClick={() => submitData()}
            centered={width <= 880}
            css={css`
              width: 290px;

              @media (max-width: 880px) {
                width: 100%;
              }
            `}
          >
            start new project
          </GradientButton>
        </div>
      </form>
    </div>
  )
}
export default ContactForm
